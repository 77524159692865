<template>
  <div class="col-md-12">
    <h1 class="text-black text-center pt-5 pb-4 section-title">
        <span>About me</span>
    </h1>
    <div class="row">
      <div class="col-md-4 d-flex justify-content-center pb-4">
        <div class="profile-picture">
          <span class="text-center d-block mb-4">
              <div>
                <img src="../../public/assets/img/larisa.jpg">
              </div>
          </span>
        </div>
      </div>
      <div class="col-md-8 text-center pb-5 d-flex justify-content-center align-items-center">
          <div class="col-md-10">
            <div class="card">
              <div class="card-header text-right">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 64 64" class="iconify" data-icon="emojione:red-circle" data-inline="false" style="transform: rotate(360deg);"><circle cx="32" cy="32" r="30" fill="#ed4c5c"></circle></svg>
                &nbsp;
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 36 36" class="iconify" data-icon="twemoji:yellow-circle" data-inline="false" style="transform: rotate(360deg);"><circle cx="18" cy="18" r="18" fill="#FDCB58"></circle></svg>
                &nbsp;
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 36 36" class="iconify" data-icon="twemoji:green-circle" data-inline="false" style="transform: rotate(360deg);"><circle cx="18" cy="18" r="18" fill="#78B159"></circle></svg>
              </div>
              <div class="card-body text-left">
                <br>
                <span class="wave">Hi!</span>
                <br>
                <br>
                <p class="text-justify">
                I'm Larisa Zaharia, a highly motivated self-taught Front End Developer,
                currently working as a Technical Support Engineer for Solaris Operating System,
                seeking to move into web development full time. <br>
                I graduated from the Politehnica University in Bucharest and have a master's
                degree in Advanced Wireless Communications.<br>
                Some of my hobbies include reading anything I can get my hands on and painting,
                which is one of my oldest and dearest hobbies.<br>
                After much soul-searching, I came to realize that web development perfectly
                blends my creative side with my technical side. I thoroughly enjoy bringing
                ideas to life in the browser and I am continuously working on improving my
                skills every time I get the opportunity.<br>
                In this portfolio, you will find a few projects I've been working on.<br>
                Feel free to leave me a Linkedin message or an Email using the icons at the bottom
                of this portfolio.
                </p>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>
