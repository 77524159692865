<template>
  <div class="col-md-12">
    <div class="col-md-12">
      <h1 class="section-title text-center pt-5 pb-4">
        <span class="text-white">Skills</span>
      </h1>
      <div class="col-md-12 text-center pb-4">
        <ul class="mx-auto">
          <li class="list-inline-item mx-3">
            <span>
              <div class="text-center skills-tile text-white">
                <i class="fa-brands fa-html5"></i>
                <p class="text-center mt-1 mb-0">HTML 5</p>
              </div>
            </span>
          </li>
          <li class="list-inline-item mx-3">
            <span>
              <div class="text-center skills-tile text-white">
                <i class="fa-brands fa-css3"></i>
                <p class="text-center mt-1 mb-0">CSS 3</p>
              </div>
            </span>
          </li>
          <li class="list-inline-item mx-3">
            <span>
              <div class="text-center skills-tile text-white">
                <i class="fa-brands fa-js"></i>
                <p class="text-center mt-1 mb-0">JavaScript</p>
              </div>
            </span>
          </li>
          <li class="list-inline-item mx-3">
            <span>
              <div class="text-center skills-tile text-white">
                <i class="fa-brands fa-bootstrap"></i>
                <p class="text-center mt-1 mb-0">Bootstrap</p>
              </div>
            </span>
          </li>
          <li class="list-inline-item mx-3">
            <span>
              <div class="text-center skills-tile text-white">
                <i class="fa-brands fa-vuejs"></i>
                <p class="text-center mt-1 mb-0">Vue</p>
              </div>
            </span>
          </li>
          <li class="list-inline-item mx-3">
            <span>
              <div class="text-center skills-tile text-white">
                <i class="fa-brands fa-node-js"></i>
                <p class="text-center mt-1 mb-0">Node JS</p>
              </div>
            </span>
          </li>
          <li class="list-inline-item mx-3">
            <span>
              <div class="text-center skills-tile text-white">
                <div class="solaris">
                <svg preserveAspectRatio="none" viewBox="0 0 210 110" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" version="1.1" width="140" height="75" id="svg31446">
                  <defs id="defs31448"/>
                  <g transform="translate(-475.89909,-769.69642)" id="layer1">
                    <path d="m 607.24884,810.88855 c 0.1226,-0.66998 1.745,-4.69998 1.745,-4.69998
                    2.01,-4.52752 0.7325,-7.7725 0.7325,-7.7725 -1.185,-4.39752 -4.5875,-7.83502
                    -4.5875,-7.83502 0.165,0.1226 1.2,2.875 1.2,2.875 2.385,6.58752 -4.01,10.36752
                    -4.01,10.36752 -1.6475,1.08748 -4.3375,3.68 -4.3375,3.68 -3.7725,3.6875
                    -2.63248,8.20748 -2.63248,8.20748 l 0.15,0.0674 c 4.4875,1.10252 8.7025,2.89
                    12.5325,5.23752 -2.5375,-4.93752 -0.7925,-10.12752 -0.7925,-10.12752"
                    id="path212" style="fill:white;fill-opacity:1;fill-rule:nonzero;stroke:none"/>
                    <path d="m 620.36534,832.24129 c -0.42,-5.43 3.055,-9.5025 3.055,-9.5025
                    0.3575,-0.57996 3.36,-3.70746 3.36,-3.70746 3.5575,-3.45504 3.59,-6.94254
                    3.59,-6.94254 0.545,-4.5225 -1.3325,-8.98 -1.3325,-8.98 0.1026,0.1726
                    0.0376,3.11504 0.0376,3.11754 -0.2425,6.99746 -7.585,8.1225 -7.585,8.1225
                    -1.94,0.39746 -5.405,1.8 -5.405,1.8 -4.5175,1.86996 -5.385,5.97246
                    -5.4825,6.55996 3.7225,2.66 7.0125,5.8775 9.7625,9.5325" id="path214"
                    style="fill:white;fill-opacity:1;fill-rule:nonzero;stroke:none"/>
                    <path d="m 646.64508,823.24453 c 0.035,0.205 -1.13,2.915 -1.13,2.915
                    -2.82248,6.405 -10.0525,4.71502 -10.0525,4.71502 -1.9475,-0.345
                    -5.685,-0.33502 -5.685,-0.33252 -5.275,0.06 -7.5725,4.1125 -7.5725,4.1125
                    l 0.2775,0.6725 c 2.295,3.67252 4.065,7.6975 5.21,11.9825 1.56,-5.3525
                    6.445,-7.92498 6.45,-7.92498 0.5475,-0.39252 4.495,-2.19752 4.495,-2.19752
                    4.58502,-1.88248 5.90752,-5.1025 5.90752,-5.1025 2.18,-4.00248 2.1,-8.84
                    2.1,-8.84" id="path216"
                    style="fill:white;fill-opacity:1;fill-rule:nonzero;stroke:none"/>
                    <path d="m 592.01934,815.07779 c -4,-3.6475 -4.2925,-8.955 -4.2925,-8.955
                    -0.15,-0.665 -0.1324,-5 -0.1276,-5 0.1874,-4.95 -2.2025,-7.495
                    -2.2025,-7.495 -2.72,-3.6525 -7.1625,-5.585 -7.1625,-5.585 0.2025,0.055
                    2.1875,2.235 2.1875,2.235 4.6475,5.225 0.1124,11.11 0.1124,11.11
                    -1.1275,1.625 -2.665,5.0275 -2.665,5.0275 -2.0175,4.555 0.31,8.1725
                    0.5675,8.5625 2.265,-0.335 4.595,-0.495 6.9525,-0.47 2.2625,0.0226
                    4.475,0.215 6.63,0.57" id="path218"
                    style="fill:white;fill-opacity:1;fill-rule:nonzero;stroke:none"/>
                    <path d="m 567.80908,808.83357 c -0.3675,-0.565 -1.9825,-4.59 -1.9825,-4.59
                    -1.6625,-4.66752 -4.8225,-6.14 -4.8225,-6.14 -3.8975,-2.37 -8.73,-2.51752
                    -8.73,-2.51752 0.2075,-0.02 2.8575,1.26252 2.8575,1.26252 6.27,3.1225
                    4.2475,10.27 4.2475,10.27 -0.44,1.92498 -0.6025,5.6625 -0.6025,5.6625
                    -0.1924,5.26248 3.755,7.75498 3.755,7.75498 l 0.1874,-0.065 c
                    3.8325,-2.23248 8.0425,-3.90498 12.4975,-4.895 -5.1325,-1.875
                    -7.4075,-6.74248 -7.4075,-6.74248" id="path220"
                    style="fill:white;fill-opacity:1;fill-rule:nonzero;stroke:none"/>
                    <path d="m 559.86434,822.27679 c -5.4375,0.14 -9.3325,-3.52
                    -9.3325,-3.52 -0.555,-0.3875 -3.535,-3.535 -3.535,-3.535 -3.2725,-3.72
                    -6.765,-3.915 -6.765,-3.915 -4.49,-0.765 -9.025,0.89 -9.025,0.89
                    0.18,-0.0926 3.1125,0.1126 3.1175,0.1126 6.975,0.585 7.7375,7.97
                    7.7375,7.97 0.3,1.955 1.53748,5.485 1.53748,5.485 1.77252,4.9625
                    6.35502,5.815 6.35502,5.815 l 0.0924,-0.0774 c 2.79,-3.5525
                    6.1025,-6.6675 9.8175,-9.225" id="path222"
                    style="fill:white;fill-opacity:1;fill-rule:nonzero;stroke:none"/>
                    <path d="m 538.07558,834.53355 c -0.6625,-0.1526 -4.6,-1.97 -4.6,-1.97
                    -4.415,-2.2575 -7.7275,-1.1475 -7.7275,-1.1475 -4.455,0.9475 -8.065,4.17
                    -8.065,4.17 0.135,-0.1574 2.9425,-1.05 2.9425,-1.05 6.7,-2.0375 10.1375,4.5475
                    10.1375,4.5475 0.9975,1.7025 3.455,4.5275 3.455,4.5225 3.4775,3.9575 8.06,3.06
                    8.06,3.06 l 0.115,-0.2275 c 1.2475,-4.3275 3.1475,-8.3725 5.5725,-12.05
                    -4.9725,2.0925 -9.89,0.145 -9.89,0.145" id="path224"
                    style="fill:white;fill-opacity:1;fill-rule:nonzero;stroke:none"/>
                  </g>
                </svg>
                <p class="text-center mt-1 mb-0">Solaris OS</p>
                </div>
              </div>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
