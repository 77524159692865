<template>
  <div class="col-md-12 mx-auto">
    <div class="col-md-12">
      <h1 class="section-title text-black text-center pt-5 pb-4">
        <span class="text-black text-center">Experience</span>
      </h1>
    </div>
  </div>
  <div class="timeline pb-4">
    <div class="container left m-0">
      <div class="content">
        <h2>Self-taught Front End Developer</h2>
        <h6>05.2021-Present,</h6>
        <p class="text-justify">
          - Started my self-taught journey in Front End development through Udemy courses in
          May 2021.<br>
          - Some of the courses I have completed are: 'The Web Developer Bootcamp' by Colt
          Steele and 'Complete Vue Mastery' by Andrei Neagoie. <br>
          - Through these courses, I have built a foundation of knowledge of HTML, CSS, JavaScript,
          - Vue, and related technologies. <br>
          - Worked on and implemented different apps, such as a music app, my wedding invitation app
          and my portfolio. <br>
          <br>
          - Technologies: HTML5, CSS3, JavaScript, Bootstrap, Vue, NodeJS, MongoDB, Git
        </p>
      </div>
    </div>
    <div class="container right m-0">
      <div class="content">
        <h2>Technical Support Engineer</h2>
        <h6>12.2017-Present,</h6>
        <h6>Oracle</h6>
        <p class="text-justify">
          - Performed troubleshooting on worldwide servers running Solaris OS for Oracle's customers
          to solve OS/Kernel issues ( i.e installation, system updates, system hangs, performance
          issues, botting issues, etc). <br>
          - Performed remote sessions with the customers via Zoom/Webex to solve critical issues by
          guiding them with all the steps/commands. <br>
          - Created knowledge documents after finding solutions/workarounds to a new issue.<br>
          - Opened BUGs when new issues were discovered and assisted Development Team to expedite
          solutions.<br>
          - Participated in 3 weeks of hands-on training at one of Oracle's offices in Burlington,
          Massachusetts to enhance OS/Kernel skills and work closely with expert engineers from
          Oracle.<br>
          - Participated in Solaris OS-related online/in-class training over the years to improve
          skills and provide a better experience to Oracle's customers.<br>
        </p>
      </div>
    </div>
    <div class="container left m-0">
      <div class="content">
        <h2>FO BSS 1st Level Operations</h2>
        <h6>10.2015-12.2017,</h6>
        <h6>Ericsson</h6>
        <p class="text-justify">
          - Monitor and troubleshoot for CRM and ETL systems. <br>
          - Managing fault analysis resolutions for BSS in production. <br>
          - Working with trouble ticket management applications and maintaining the
          service delivery level within the agreed SLAs. <br>
          - Solving problems in the BSS area based on the existing procedures.<br>
          - Running periodical analysis using scripts on systems, required for
          maintenance and prevention.<br>
          - Using telecomunication knowledge to manage issues and customer demands.
          </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Experience',
  methods: {

  },
};
</script>
