<template>
  <footer class="text-white">
    <div class="col-md-12 text-center pt-2 pb-4">
      <div>
          <span class="m-4">
              <a class="text-white" href="https://github.com/larisa-gheorghe/">
              <i class="fa-brands fa-github"></i></a>
          </span>
          <span class="m-4">
              <a class="text-white" href="https://www.linkedin.com/in/larisa-camelia-gheorghe/">
              <i class="fa-brands fa-linkedin"></i></a>
          </span>
          <span class="m-4">
              <a class="text-white" href="mailto:larisa.camelia.gheorghe@gmail.com">
              <i class="fa-solid fa-envelope"></i></a>
          </span>
      </div>
      <div class="text-center py-1">
        <small>Copyright &copy; 2022, Larisa Gheorghe</small>
      </div>
    </div>
  </footer>
</template>
